import {
  type AbstractMesh,
  ArcRotateCamera,
  type ArcRotateCameraPointersInput,
  type AssetContainer,
  type BaseTexture,
  Camera,
  Color3,
  Color4,
  CubeTexture,
  DiscBuilder,
  Engine,
  GizmoManager,
  type GroundMesh,
  HighlightLayer,
  Layer,
  Matrix,
  Mesh,
  MeshBuilder,
  type Nullable,
  type PBRMaterial,
  type PlaneDragGizmo,
  PointerEventTypes,
  Quaternion,
  Scene,
  SceneLoader,
  StandardMaterial,
  type Texture,
  Vector3,
  type ScaleGizmo,
  AxisScaleGizmo,
  ImageProcessingConfiguration,
} from '@babylonjs/core'
import '@babylonjs/loaders/glTF'
import { GridMaterial } from '@babylonjs/materials'
import _ from 'lodash'
import type { DocBlocs } from '../store/doc/selectors'
import type { DocDiff } from '../store/doc/state'
import { SceneObjectPropertyType } from '../store/doc/types'
import {
  type EmissionPayload,
  type HdriData,
  ImageTextureNode,
  IN_TO_M,
  type MaterialPayload,
  type PresetPropertyData,
  type RenderData,
  type SceneObjectData,
  type ScreenTexturePayload,
  unitFromM,
  unitFromRadian,
  ScalingMode,
} from './RenderData'
import '@babylonjs/core/Debug/debugLayer'
import '@babylonjs/inspector'
const TEMP_VECTOR3 = new Vector3()
const IS_ROOT_CONTAINER = Symbol('ROOT_CONTAINER')

const LAYOUT_CAMERA_MIN_DISTANCE = 0.5
const LAYOUT_CAMERA_MAX_DISTANCE = 5

interface Dictionary<T> {
  [index: string]: T
}

interface LayerImage {
  layer: Layer
  texture: Texture
}

interface Ground {
  mesh: GroundMesh
  material: GridMaterial
}

// type ScreenTexture = {
//   defaultUri: string
// }

// type ScreenReflection = {
//   defaultUri: string
// }

type SceneObjectStateCommon = {
  uuid: string
  sceneFilename: string
  rootContainer: Mesh
  isSelected: boolean
}

type SceneObjectStateStatusLoading = SceneObjectStateCommon & {
  status: 'loading'
}

type SceneObjectStateStatusLoaded = SceneObjectStateCommon & {
  status: 'loaded'
  assetContainer: AssetContainer
  allMeshes: { [key: string]: AbstractMesh }
  defaultTextures: {
    [key in ImageTextureNode]?: string | null
  }
}

type SceneObjectState =
  | SceneObjectStateStatusLoading
  | SceneObjectStateStatusLoaded

export interface RenderState {
  canvas: HTMLCanvasElement
  onLoadUpdate: () => void
  onInputUpdate: () => void

  scene?: Scene
  gizmoManager?: GizmoManager
  gizmoContainer?: AbstractMesh
  highlightLayer?: HighlightLayer
  renderCamera?: ArcRotateCamera
  layoutCamera?: ArcRotateCamera

  background?: LayerImage
  foreground?: LayerImage
  ground?: Ground
  hdriTexture?: CubeTexture

  sceneObjects?: Dictionary<SceneObjectState>
}

interface AssetLoader {
  promise: Promise<AssetContainer>
  assetContainer?: AssetContainer
}

interface LocalState {
  data: RenderData
  state: RenderState
  assetLoaders: Dictionary<AssetLoader | void>
  isDirty: boolean
  hasInputChanges: boolean
  isInputActive: boolean
  lastGizmoPointerPos: { x: number; y: number }
  orthoZoomDistanceHalf: number
}

interface DumbVector3 {
  x: number
  y: number
  z: number
}

// -----------
// LOCAL STATE
// -----------
const LOCAL_STATE_MAP = new WeakMap<Scene, LocalState>()

const getLocalState = (scene: Scene): LocalState => {
  const localState = LOCAL_STATE_MAP.get(scene)
  if (!localState) {
    throw new Error(`Can not find localState for scene: ${scene}`)
  }

  return localState
}

const setDirty = (
  scene: Scene,
  hasInputChanges: boolean = false,
  isInputActive: boolean | undefined = undefined,
) => {
  const localState = getLocalState(scene)
  localState.isDirty = true
  if (hasInputChanges) {
    localState.hasInputChanges = true
  }
  if (isInputActive !== undefined) {
    localState.isInputActive = isInputActive
  }
}

const createDragPlaneGizmoMesh = (planeDragGizmo: PlaneDragGizmo) => {
  const gizmoLayer = planeDragGizmo.gizmoLayer

  const planeDragMesh = DiscBuilder.CreateDisc(
    'dragPlane',
    {
      radius: 0.08,
      sideOrientation: 2,
    },
    gizmoLayer.utilityLayerScene,
  )
  planeDragMesh.lookAt(Vector3.Up())

  const mat = new StandardMaterial('dragPlane', gizmoLayer.utilityLayerScene)
  mat.diffuseColor = new Color3(0, 0.5, 0)
  mat.specularColor = new Color3(0, 0.4, 0)
  mat.alpha = 0.3

  const matHover = new StandardMaterial(
    'dragPlaneHover',
    gizmoLayer.utilityLayerScene,
  )
  matHover.diffuseColor = new Color3(0.2, 0.7, 0.2)
  matHover.alpha = 0.3

  const matActive = new StandardMaterial(
    'dragPlaneHover',
    gizmoLayer.utilityLayerScene,
  )
  matActive.diffuseColor = new Color3(0.3, 0.8, 0.3)
  matActive.alpha = 0.3

  planeDragMesh.material = mat
  let isHovered = false
  let isDragged = false
  const updateMaterial = () => {
    const material = isDragged ? matActive : isHovered ? matHover : mat
    planeDragMesh.material = material
  }

  gizmoLayer.utilityLayerScene.onPointerObservable.add(pointerInfo => {
    isHovered = pointerInfo.pickInfo?.pickedMesh === planeDragMesh
    updateMaterial()
  })
  planeDragGizmo.dragBehavior.onDragStartObservable.add(() => {
    isDragged = true
    updateMaterial()
  })
  planeDragGizmo.dragBehavior.onDragEndObservable.add(() => {
    isDragged = false
    updateMaterial()
  })

  const gizmoLight = gizmoLayer._getSharedGizmoLight()
  gizmoLight.includedOnlyMeshes =
    gizmoLight.includedOnlyMeshes.concat(planeDragMesh)

  return planeDragMesh
}

const onIsSelectedChange = (
  renderState: RenderState,
  changedSceneObjects: SceneObjectState[],
  becomesSelected: boolean,
) => {
  // Update the renderState first.
  _.forEach(changedSceneObjects, changedSceneObject => {
    changedSceneObject.isSelected = becomesSelected
  })

  const allSceneObjects = renderState.sceneObjects
  const selectedSceneObjects = _.filter(renderState.sceneObjects, {
    isSelected: true,
  })

  // Remove from gizmoContainer
  _.forEach(allSceneObjects, sceneObject =>
    sceneObject.rootContainer.setParent(null),
  )

  // Reset gizmoContainer state
  const positions = _.map(
    selectedSceneObjects,
    selectedSceneObject => selectedSceneObject.rootContainer.position,
  )
  if (positions.length === 1) {
    renderState.gizmoContainer!.position.copyFrom(positions[0])
    renderState.gizmoContainer!.rotationQuaternion =
      selectedSceneObjects[0].rootContainer.rotationQuaternion!.clone()
  } else if (positions.length > 1) {
    renderState.gizmoContainer!.position.set(
      _.meanBy(positions, 'x'),
      0, // Always on ground.
      _.meanBy(positions, 'z'),
    )
    renderState.gizmoContainer!.rotationQuaternion?.set(0, 0, 0, 1)
  }
  renderState.gizmoContainer!.computeWorldMatrix()
  renderState.gizmoManager!.attachToMesh(
    positions.length > 0 ? renderState.gizmoContainer! : null,
  )

  // Add to gizmoContainer
  _.forEach(selectedSceneObjects, sceneObject => {
    sceneObject.rootContainer.setParent(renderState.gizmoContainer!)
  })

  _.forEach(changedSceneObjects, sceneObjectState => {
    const { rootContainer } = sceneObjectState
    const childMeshes = sceneObjectState.rootContainer.getChildMeshes()

    if (becomesSelected) {
      _.each(childMeshes, mesh => {
        const className = mesh.getClassName()
        if (className === 'Mesh') {
          renderState.highlightLayer!.addMesh(mesh as Mesh, Color3.Green())
          mesh.renderingGroupId = 1
        } else {
          // TODO Support InstancedMesh, likely by converting all to regular Meshes on load
          console.warn('Not supported mesh type for highlight', className)
        }
      })
    } else {
      rootContainer.parent = null
      _.each(childMeshes, mesh => {
        renderState.highlightLayer!.removeMesh(mesh as Mesh)
        mesh.renderingGroupId = 0
      })
    }
  })
}

const createGizmo = (scene: Scene, renderData: RenderData) => {
  const gizmoManager = new GizmoManager(scene)
  scene.onPointerObservable.add(eventData => {
    const { pickInfo, event, type } = eventData
    const { state } = getLocalState(scene)
    const isToggle = event.metaKey || event.ctrlKey

    // Released outside any pickable meshes
    if (type === PointerEventTypes.POINTERUP) {
      if (!pickInfo?.pickedMesh) {
        if (isToggle) {
          return
        }

        onIsSelectedChange(
          state,
          _.filter(state.sceneObjects, {
            isSelected: true,
          }),
          false,
        )
      }
      return
    }

    // TODO Move back to POINTERPICK after we resolve the extra delay - needs simple reproducible case on BJS playground and report in BJS forum
    // Both down and up events were on the same mesh
    if (type !== PointerEventTypes.POINTERDOWN) {
      return
    }
    if (!pickInfo?.pickedMesh) {
      return
    }

    const rootMesh = getRootMesh(pickInfo.pickedMesh)
    const sceneObjectState = _.find(state.sceneObjects, {
      rootContainer: rootMesh,
    })

    if (!sceneObjectState) {
      return
    }

    const sceneObjectData = renderData.sceneObjects[sceneObjectState.uuid] as
      | SceneObjectData
      | undefined

    if (!sceneObjectState.rootContainer.isPickable) {
      onIsSelectedChange(
        state,
        _.filter(state.sceneObjects, {
          isSelected: true,
        }),
        false,
      )
      scene.render()
      return
    }

    const { scaleGizmo } = gizmoManager.gizmos

    if (isToggle) {
      onIsSelectedChange(
        state,
        [sceneObjectState],
        !sceneObjectState.isSelected,
      )

      const selectedObjectsState = _.filter(
        renderData.sceneObjects,
        'isSelected',
      )
      const selectedObjectState = _.first(selectedObjectsState)
      const selectedObjectData =
        selectedObjectState && renderData.sceneObjects[selectedObjectState.uuid]
      const isSingleObjectSelected = selectedObjectsState.length === 1
      scaleGizmo!.uniformScaleGizmo.isEnabled =
        isSingleObjectSelected &&
        selectedObjectData?.scalingMode === ScalingMode.Isotropic
      scaleGizmo!.xGizmo.isEnabled =
        isSingleObjectSelected &&
        selectedObjectData?.scalingMode === ScalingMode.Anisotropic
      scaleGizmo!.yGizmo.isEnabled =
        isSingleObjectSelected &&
        selectedObjectData?.scalingMode === ScalingMode.Anisotropic
      scaleGizmo!.zGizmo.isEnabled =
        isSingleObjectSelected &&
        selectedObjectData?.scalingMode === ScalingMode.Anisotropic

      scene.render()
    } else {
      onIsSelectedChange(
        state,
        _.filter(state.sceneObjects, {
          isSelected: true,
        }),
        false,
      )
      onIsSelectedChange(state, [sceneObjectState], true)

      // Here only single object will be selected.
      scaleGizmo!.uniformScaleGizmo.isEnabled =
        sceneObjectData?.scalingMode === ScalingMode.Isotropic
      scaleGizmo!.xGizmo.isEnabled =
        sceneObjectData?.scalingMode === ScalingMode.Anisotropic
      scaleGizmo!.yGizmo.isEnabled =
        sceneObjectData?.scalingMode === ScalingMode.Anisotropic
      scaleGizmo!.zGizmo.isEnabled =
        sceneObjectData?.scalingMode === ScalingMode.Anisotropic

      scene.render()
    }
  })

  gizmoManager.positionGizmoEnabled = true
  gizmoManager.rotationGizmoEnabled = true
  gizmoManager.scaleGizmoEnabled = true

  gizmoManager.usePointerToAttachGizmos = false
  gizmoManager.onAttachedToMeshObservable.add(() => {
    setDirty(scene)
  })

  const { rotationGizmo, positionGizmo, scaleGizmo } = gizmoManager.gizmos

  scaleGizmo!.xGizmo = new AxisScaleGizmo(
    new Vector3(1, 0, 0),
    Color3.Yellow().scale(0.5),
    scaleGizmo!.gizmoLayer,
    scaleGizmo! as ScaleGizmo,
  )
  scaleGizmo!.yGizmo = new AxisScaleGizmo(
    new Vector3(0, 1, 0),
    Color3.Yellow().scale(0.5),
    scaleGizmo!.gizmoLayer,
    scaleGizmo! as ScaleGizmo,
  )
  scaleGizmo!.zGizmo = new AxisScaleGizmo(
    new Vector3(0, 0, 1),
    Color3.Yellow().scale(0.5),
    scaleGizmo!.gizmoLayer,
    scaleGizmo! as ScaleGizmo,
  )

  scaleGizmo!.xGizmo.scaleRatio = 0.8
  scaleGizmo!.yGizmo.scaleRatio = 0.8
  scaleGizmo!.zGizmo.scaleRatio = 0.8

  scaleGizmo!.sensitivity = 3

  // Scale gizmos enabled later.
  scaleGizmo!.uniformScaleGizmo.isEnabled = false
  scaleGizmo!.xGizmo.isEnabled = false
  scaleGizmo!.yGizmo.isEnabled = false
  scaleGizmo!.zGizmo.isEnabled = false

  positionGizmo!.updateGizmoRotationToMatchAttachedMesh = false
  rotationGizmo!.yGizmo.updateGizmoRotationToMatchAttachedMesh = false

  positionGizmo!.yPlaneGizmo.isEnabled = true
  positionGizmo!.yGizmo.isEnabled = true

  const planeDragMesh = createDragPlaneGizmoMesh(
    positionGizmo!.yPlaneGizmo as PlaneDragGizmo,
  )
  positionGizmo!.yPlaneGizmo.setCustomMesh(planeDragMesh)

  positionGizmo!.scaleRatio = 1.2

  const scaleGizmos = [
    scaleGizmo!.xGizmo,
    scaleGizmo!.yGizmo,
    scaleGizmo!.zGizmo,
    scaleGizmo!.uniformScaleGizmo,
  ]

  const gizmos = [
    positionGizmo!.xGizmo,
    positionGizmo!.yGizmo,
    positionGizmo!.zGizmo,
    positionGizmo!.xPlaneGizmo,
    positionGizmo!.yPlaneGizmo,
    positionGizmo!.zPlaneGizmo,
    rotationGizmo!.xGizmo,
    rotationGizmo!.yGizmo,
    rotationGizmo!.zGizmo,
    ...scaleGizmos,
  ]

  // Move scaling from gizmoContainer to sceneObject's rootContainer on drag end.
  _.forEach(scaleGizmos, ({ dragBehavior }) => {
    dragBehavior.onDragEndObservable.add(() => {
      const { state } = getLocalState(scene)

      const gizmoContainer = state.gizmoContainer!

      const selectedObjects = _.filter(state.sceneObjects, 'isSelected')

      _.forEach(selectedObjects, ({ rootContainer }) => {
        rootContainer.setParent(null)
      })

      gizmoContainer.scaling = new Vector3(1, 1, 1)

      _.forEach(selectedObjects, ({ rootContainer }) => {
        rootContainer.setParent(gizmoContainer)
      })
    })
  })

  _.forEach(gizmos, ({ dragBehavior }) => {
    dragBehavior.onDragStartObservable.add(() => {
      setDirty(scene, false, true)
    })

    dragBehavior.onDragObservable.add(() => {
      setDirty(scene, true, true)
    })

    dragBehavior.onDragEndObservable.add(() => {
      setDirty(scene, true, false)
    })
  })

  return gizmoManager
}

const updateGizmo = (renderState: RenderState, renderData: RenderData) => {
  const selectedObjects = _.filter(renderData.sceneObjects, 'isSelected')
  const selectedObject = _.first(selectedObjects)

  const isDisabled = _.some(
    selectedObjects,
    ({ isLocked, isDraggable }) => isLocked || isDraggable === false,
  )
  const isAdvanced = !isDisabled && !!renderData.isGizmoAdvancedMode

  const gizmoManager = renderState.gizmoManager as GizmoManager

  const { rotationGizmo, positionGizmo, scaleGizmo } = gizmoManager.gizmos

  const isSingleObjectSelected = selectedObjects.length === 1
  scaleGizmo!.uniformScaleGizmo.isEnabled =
    isSingleObjectSelected &&
    selectedObject!.scalingMode === ScalingMode.Isotropic
  scaleGizmo!.xGizmo.isEnabled =
    isSingleObjectSelected &&
    selectedObject!.scalingMode === ScalingMode.Anisotropic
  scaleGizmo!.yGizmo.isEnabled =
    isSingleObjectSelected &&
    selectedObject!.scalingMode === ScalingMode.Anisotropic
  scaleGizmo!.zGizmo.isEnabled =
    isSingleObjectSelected &&
    selectedObject!.scalingMode === ScalingMode.Anisotropic

  positionGizmo!.yGizmo.isEnabled = !isDisabled
  positionGizmo!.yPlaneGizmo.isEnabled = !isDisabled
  rotationGizmo!.yGizmo.isEnabled = !isDisabled

  positionGizmo!.xGizmo.isEnabled = isAdvanced
  positionGizmo!.zGizmo.isEnabled = isAdvanced

  rotationGizmo!.xGizmo.isEnabled = isAdvanced
  rotationGizmo!.zGizmo.isEnabled = isAdvanced

  return renderState
}

// -----
// UTILS
// -----
const fromDumbVector3 = (dest: Vector3, source: DumbVector3) => {
  dest.set(source.x, source.y, source.z)
}

// -----------------
// IN IMPLEMENTATION
// -----------------
const updateScene = (
  renderState: RenderState,
  state: Scene | undefined,
  data: RenderData,
) => {
  if (state) {
    return renderState
  }

  const engine = new Engine(
    renderState.canvas,
    true,
    {
      limitDeviceRatio: 3,
      doNotHandleTouchAction: true,
      alpha: true,
      stencil: true,
    },
    true,
  )
  const newScene = new Scene(engine)
  newScene.clearColor = new Color4(0.75, 0.75, 0.75, 1)

  // Tone mapping.
  newScene.imageProcessingConfiguration.toneMappingEnabled = true
  newScene.imageProcessingConfiguration.toneMappingType =
    ImageProcessingConfiguration.TONEMAPPING_KHR_PBR_NEUTRAL

  const localState: LocalState = {
    data,
    state: renderState,
    assetLoaders: {},
    isDirty: true,
    hasInputChanges: false,
    isInputActive: false,
    lastGizmoPointerPos: {
      x: Number.MIN_VALUE,
      y: Number.MIN_VALUE,
    },
    orthoZoomDistanceHalf: IN_TO_M * 12 * 3,
  }
  LOCAL_STATE_MAP.set(newScene, localState)

  const gizmoManager = createGizmo(newScene, data)

  // Probably should use TransformNode
  const gizmoContainer = new Mesh('gizmoContainer', newScene)
  gizmoContainer.position = new Vector3(0, 0, 0)
  gizmoContainer.rotationQuaternion = new Quaternion()

  const highlightLayer = new HighlightLayer('selection', newScene, {
    mainTextureRatio: 4,
    isStroke: true,
  })
  highlightLayer.renderingGroupId = 1
  newScene.setRenderingAutoClearDepthStencil(1, false, false)

  let inertialRadiusOffset = 0
  let buttonsPressed = 0
  let isInputActive = false
  const updateInputActivity = (camera: ArcRotateCamera) => {
    const pointersInput = camera.inputs.attached
      .pointers as ArcRotateCameraPointersInput
    const _buttonsPressed = (pointersInput as any)._buttonsPressed
    if (buttonsPressed === 0 && _buttonsPressed !== 0) {
      localState.isInputActive = true
    }
    if (buttonsPressed !== 0 && _buttonsPressed === 0) {
      localState.isInputActive = false
    }
    buttonsPressed = _buttonsPressed

    const offset = camera.inertialRadiusOffset
    if (inertialRadiusOffset === 0 && offset !== 0) {
      localState.isInputActive = true
    }
    if (inertialRadiusOffset !== 0 && offset === 0) {
      localState.isInputActive = false
    }
    inertialRadiusOffset = offset
  }

  const checkGizmoPointerMoved = () => {
    const pos = localState.lastGizmoPointerPos
    if (pos.x !== newScene.pointerX || pos.y !== newScene.pointerY) {
      localState.lastGizmoPointerPos.x = newScene.pointerX
      localState.lastGizmoPointerPos.y = newScene.pointerY
      return true
    }

    return false
  }

  engine.runRenderLoop(() => {
    const camera = newScene.activeCamera! as ArcRotateCamera
    const inertiaProps = _.pick(camera, [
      'inertialAlphaOffset',
      'inertialBetaOffset',
      'inertialPanningX',
      'inertialPanningY',
      'inertialRadiusOffset',
    ])
    const hasInertiaChanges = _.some(inertiaProps)
    const layoutCamera = localState.state.layoutCamera!

    const hasInputChanges =
      localState.hasInputChanges ||
      hasInertiaChanges ||
      checkGizmoPointerMoved()

    updateInputActivity(camera)

    if (layoutCamera === camera && hasInertiaChanges) {
      updateLayoutCameraTransform(localState.state, localState.data, camera)
    }

    const isInputCompleted = isInputActive && !localState.isInputActive
    isInputActive = localState.isInputActive
    if (hasInputChanges) {
      localState.isDirty = true
    }

    if (localState.isDirty) {
      newScene.render()
    }

    localState.isDirty = false
    localState.hasInputChanges = false

    if (isInputCompleted) {
      renderState.onInputUpdate()
    }
  })

  return {
    ...renderState,
    scene: newScene,
    gizmoManager,
    gizmoContainer,
    highlightLayer,
  }
}

const updateRenderCamera = (
  renderState: RenderState,
  renderData: RenderData,
): RenderState => {
  const { camera: data, canvasSize } = renderData

  let camera = renderState.renderCamera
  if (!camera) {
    camera = new ArcRotateCamera(
      'renderCamera',
      0,
      0,
      1,
      new Vector3(0, 0, 0),
      renderState.scene!,
      true,
    )
    camera.fov = data.fov
    camera.lowerRadiusLimit = IN_TO_M

    camera.minZ = 0.001
    camera.inertia = 0
    camera.panningInertia = 0
    camera.wheelPrecision = 100

    camera.attachControl(renderState.canvas, true)
    renderState.scene!.activeCamera = camera
  }

  // UPDATE
  camera.fovMode =
    canvasSize.width > canvasSize.height
      ? Camera.FOVMODE_HORIZONTAL_FIXED
      : Camera.FOVMODE_VERTICAL_FIXED

  camera.fov = data.fov
  camera.radius = data.radius
  fromDumbVector3(camera.target, data.target)
  camera.alpha = data.rotation.y - Math.PI / 2
  camera.beta = data.rotation.x

  // TODO ROLL

  if (data.isLocked) {
    camera.detachControl()
  } else {
    camera.attachControl(renderState.canvas, true)
  }

  if (renderState.renderCamera === camera) {
    return renderState
  }
  return { ...renderState, renderCamera: camera }
}

const updateLayoutCamera = (
  renderState: RenderState,
  renderData: RenderData,
): RenderState => {
  if (!renderData.isLayoutMode) {
    return renderState
  }

  let camera = renderState.layoutCamera
  if (!camera) {
    camera = new ArcRotateCamera(
      'layoutCamera',
      -Math.PI / 2,
      0,
      1,
      new Vector3(0, 0, 0),
      renderState.scene!,
      true,
    )
    camera.mode = Camera.ORTHOGRAPHIC_CAMERA
    camera.lowerRadiusLimit = camera.radius
    camera.upperRadiusLimit = camera.radius
    camera.lowerAlphaLimit = camera.alpha
    camera.upperAlphaLimit = camera.alpha
    camera.lowerBetaLimit = camera.beta
    camera.upperBetaLimit = camera.beta

    camera.fovMode = Camera.FOVMODE_VERTICAL_FIXED

    camera.minZ = 0.001
    camera.inertia = 0
    camera.panningInertia = 0
    camera.panningSensibility = 50
    camera.wheelPrecision = 100

    camera.angularSensibilityX = 1
    camera.angularSensibilityY = 1
  }

  updateLayoutCameraTransform(renderState, renderData, camera)

  if (renderState.layoutCamera === camera) {
    return renderState
  }
  return {
    ...renderState,
    layoutCamera: camera,
  }
}

const updateLayoutCameraTransform = (
  renderState: RenderState,
  renderData: RenderData,
  camera: ArcRotateCamera,
) => {
  const scene = renderState.scene!
  const localState = getLocalState(scene)
  const { canvasSize } = renderData
  const zoomDelta = -camera.inertialRadiusOffset
  const panXDelta = -camera.inertialAlphaOffset
  const panYDelta = camera.inertialBetaOffset

  let zoomDistance = localState.orthoZoomDistanceHalf * (1 + zoomDelta)

  // Clamp distance to min/max.
  zoomDistance = Math.max(
    LAYOUT_CAMERA_MIN_DISTANCE,
    Math.min(LAYOUT_CAMERA_MAX_DISTANCE, zoomDistance),
  )

  const aspectRatio = canvasSize.width / canvasSize.height
  const xRatio = aspectRatio > 0 ? aspectRatio : 1
  const yRatio = aspectRatio > 0 ? 1 : aspectRatio

  camera.orthoBottom = -zoomDistance * yRatio
  camera.orthoTop = zoomDistance * yRatio
  camera.orthoLeft = -zoomDistance * xRatio
  camera.orthoRight = zoomDistance * xRatio

  camera.target.x +=
    (zoomDistance - localState.orthoZoomDistanceHalf) *
    xRatio *
    (scene.pointerX / canvasSize.width - 0.5) *
    -2
  camera.target.z +=
    (zoomDistance - localState.orthoZoomDistanceHalf) *
    yRatio *
    (scene.pointerY / canvasSize.height - 0.5) *
    2

  camera.target.x +=
    ((xRatio * panXDelta) / canvasSize.width) * zoomDistance * -2
  camera.target.z +=
    ((yRatio * panYDelta) / canvasSize.height) * zoomDistance * -2

  // Assumes FOVMODE_VERTICAL_FIXED
  camera.panningSensibility = canvasSize.height / zoomDistance / 2

  localState.orthoZoomDistanceHalf = zoomDistance
}

const updateActiveCamera = (
  renderState: RenderState,
  renderData: RenderData,
): RenderState => {
  const { renderCamera, layoutCamera } = renderState
  const camera = renderData.isLayoutMode ? layoutCamera : renderCamera

  if (!camera || renderState.scene!.activeCamera === camera) {
    return renderState
  }

  if (renderState.scene!.activeCamera) {
    renderState.scene!.activeCamera.detachControl(renderState.canvas)
  }

  camera.attachControl(renderState.canvas, true)
  renderState.scene!.activeCamera = camera

  return renderState
}

const updateCanvasSize = (
  renderState: RenderState,
  renderData: RenderData,
): RenderState => {
  const isWider = renderData.canvasSize.width > renderData.canvasSize.height

  if (
    renderState.canvas.width === renderData.canvasSize.width &&
    renderState.canvas.height === renderData.canvasSize.height &&
    isWider &&
    renderState.renderCamera!.fovMode === Camera.FOVMODE_HORIZONTAL_FIXED
  ) {
    return renderState
  }

  const engine = renderState.scene!.getEngine()
  const scaleFactor = engine.getHardwareScalingLevel()
  engine.setSize(
    renderData.canvasSize.width / scaleFactor,
    renderData.canvasSize.height / scaleFactor,
  )
  renderState.renderCamera!.fovMode = isWider
    ? Camera.FOVMODE_HORIZONTAL_FIXED
    : Camera.FOVMODE_VERTICAL_FIXED

  return renderState
}

const updateLayerImage = (
  renderState: RenderState,
  state: LayerImage | undefined,
  renderData: RenderData,
  name: 'background' | 'foreground',
): RenderState => {
  const url = renderData[name]
  if (!url) {
    if (!state) {
      return renderState
    }
    state.layer.texture = null
    return renderState
  }
  let layer = state?.layer

  const scaleLayer = (layer: Layer) => {
    const texture = layer.texture
    if (!texture) {
      return
    }
    const tSize = texture.getBaseSize()
    const tAspectRatio = tSize.width / tSize.height
    const sAspectRatio = renderState.canvas.width / renderState.canvas.height
    const textureAspectRatio = sAspectRatio / tAspectRatio

    // Migration:
    texture.scale(textureAspectRatio)
    // layer.scale.x = Math.min(textureAspectRatio, 1)
    // layer.scale.y = Math.min(1 / textureAspectRatio, 1)
    // layer.offset.x = (1 - texture.uScale) / 2
    // layer.offset.y = (1 - texture.vScale) / 2
    // Todo(MaikuMori): Remove if works.

    // texture.uScale = Math.min(textureAspectRatio, 1)
    // texture.vScale = Math.min(1 / textureAspectRatio, 1)
    // texture.uOffset = (1 - texture.uScale) / 2
    // texture.vOffset = (1 - texture.vScale) / 2
  }

  const onLoad = () => {
    if (layer) {
      scaleLayer(layer)
    }
    setDirty(renderState.scene!)
  }

  if (state) {
    if (state.texture.url !== url) {
      state.texture.updateURL(url, undefined, onLoad)
    }
    state.layer.texture = renderData.isLayoutMode ? null : state.texture
    scaleLayer(state.layer)
    return renderState
  }

  layer = new Layer(name, url, renderState.scene!, name === 'background')
  const texture = layer.texture!
  ;(texture as Texture).onLoadObservable.add(onLoad)
  return {
    ...renderState,
    [name]: {
      layer,
      texture,
    },
  }
}

const updateHdri = (
  renderState: RenderState,
  state: CubeTexture | undefined,
  data: HdriData | void,
): RenderState => {
  if (!data) {
    if (!state) {
      return renderState
    }
    renderState.scene!.environmentTexture = null
    return renderState
  }

  const onLoad = () => setDirty(renderState.scene!)

  if (state) {
    if (state.url !== data.texture) {
      state.updateURL(data.texture, undefined, onLoad)
    }
    const oldMatrix = state.getReflectionTextureMatrix()
    // TODO consider introducing working Matrix for performance
    const newMatrix = Matrix.RotationY(data.rotation)
    if (!_.isEqual(oldMatrix.m, newMatrix.m)) {
      state.setReflectionTextureMatrix(newMatrix)
    }
    return renderState
  }

  const newValue = CubeTexture.CreateFromPrefilteredData(
    data.texture,
    renderState.scene!,
  )
  // Cover for missing load observable
  newValue.updateURL(data.texture, undefined, onLoad)
  newValue.gammaSpace = false
  newValue.setReflectionTextureMatrix(Matrix.RotationY(data.rotation))
  renderState.scene!.environmentTexture = newValue
  return { ...renderState, hdriTexture: newValue }
}

const updateGround = (
  renderState: RenderState,
  state: Ground | undefined,
  data: boolean | undefined,
): RenderState => {
  if (state) {
    if (state.mesh.isEnabled() !== (data || false)) {
      state.mesh.setEnabled(data || false)
    }
    return renderState
  }

  const material = new GridMaterial('ground', renderState.scene!)
  material.backFaceCulling = false
  // hides the fill, shows only lines
  material.opacity = 0.99
  // 1 foot
  material.gridRatio = 0.3048
  material.majorUnitFrequency = 8
  const mesh = MeshBuilder.CreateGround(
    'groundGrid',
    { width: 100, height: 100 },
    renderState.scene,
  ) as GroundMesh
  mesh.material = material

  // Raise slightly above ground to show grid on top of ground plane asset
  // Double of `camera.minZ` to prevent Z-fighting
  mesh.position.y = 0.002

  // Ground is not pickable.
  mesh.isPickable = false

  return {
    ...renderState,
    ground: {
      mesh,
      material,
    },
  }
}

const updateSceneObjects = (
  renderState: RenderState,
  stateMap: Dictionary<SceneObjectState> | undefined,
  dataMap: Dictionary<SceneObjectData | undefined> | undefined,
): RenderState => {
  const oldKeys = _.keys(stateMap)
  const newKeys = _.keys(dataMap)
  const allKeys = _.union(newKeys, oldKeys)

  const newSceneObjectStateMap = _.transform<
    string,
    Dictionary<SceneObjectState>
  >(
    allKeys,
    (result, uuid) => {
      const sceneObjectState = stateMap?.[uuid]
      const sceneObjectData = dataMap?.[uuid]
      const newSceneObjectState = updateSceneObject(
        renderState,
        sceneObjectState,
        sceneObjectData,
      )
      if (newSceneObjectState) {
        result[uuid] = newSceneObjectState
      } else {
        delete result[uuid]
      }
    },
    {},
  )

  return {
    ...renderState,
    sceneObjects: newSceneObjectStateMap,
  }
}

const removeSceneObject = (
  renderState: RenderState,
  state: SceneObjectState,
  localState: LocalState,
) => {
  if (state.status === 'loaded') {
    onIsSelectedChange(renderState, [state], false)
    state.assetContainer.dispose()
  } else {
    // Can not cancel standard promise - leave it be
    delete localState.assetLoaders[state.uuid]
  }
  state.rootContainer.dispose()
}

const loadSceneObject = (
  data: SceneObjectData,
  scene: Scene,
  onLoadUpdate: () => void,
  localState: LocalState,
): SceneObjectState => {
  const { uuid, rootUrl, sceneFilename, isSelected, hasPlaceholder } = data

  const rootContainer: Mesh = new Mesh('placeholder_' + sceneFilename, scene)
  rootContainer.rotationQuaternion = new Quaternion()
  rootContainer.metadata = {
    isRootContainer: IS_ROOT_CONTAINER,
  }

  let placeholder: Mesh | undefined
  let placeholderMaterial: StandardMaterial | undefined

  if (hasPlaceholder) {
    placeholder = MeshBuilder.CreateBox('placeholder', {
      size: 0.1,
    })
    placeholder.position.y = 0.05
    placeholder.parent = rootContainer

    placeholderMaterial = new StandardMaterial('placeholder', scene)
    placeholderMaterial.emissiveColor.set(0, 1, 0)
    placeholderMaterial.wireframe = true
    placeholder.material = placeholderMaterial
  }

  const promise = SceneLoader.LoadAssetContainerAsync(
    rootUrl,
    sceneFilename,
    // `${sceneFilename}?ignoreCache&random=${Math.random()}`,
    scene,
  )
  localState.assetLoaders[uuid] = { promise }

  promise
    .then(assetContainer => {
      const gltfUrl = `https:${rootUrl}${sceneFilename}`
      console.info(`Loaded sceneObject: ${gltfUrl}`)

      const loadState = localState.assetLoaders[uuid]
      if (loadState) {
        // TODO
        // Maintain isReady state in render loop
        // Prevent rendering partial state
        // Remove when https://github.com/OrangeLV/RenderTool/issues/310 is resolved
        scene.executeWhenReady(() => {
          setDirty(scene)
        })

        loadState.assetContainer = assetContainer
        onLoadUpdate()
      }
    })
    .catch((reason: any) => {
      if (placeholderMaterial) {
        placeholderMaterial.emissiveColor.set(1, 0, 0)
      }
      setDirty(scene)
      console.error(
        `Can not load sceneObject - reason: ${reason}. rootUrl: ${rootUrl}, sceneFilename: ${sceneFilename}`,
      )
    })

  return {
    uuid,
    sceneFilename,
    rootContainer,
    isSelected,
    status: 'loading',
  }
}

const getParentMeshes = (mesh: AbstractMesh) => {
  const parentMeshes = []

  let currentMesh = mesh
  while (true) {
    const parentMesh = currentMesh.parent as Mesh | undefined
    if (!parentMesh) {
      break
    }

    currentMesh = parentMesh
    parentMeshes.push(parentMesh)
  }

  return parentMeshes
}

const getRootMesh = (mesh: AbstractMesh): AbstractMesh => {
  const parentMeshes = getParentMeshes(mesh)

  return _.find(
    parentMeshes,
    m => m.metadata?.isRootContainer === IS_ROOT_CONTAINER,
  )!
}

const getAllMeshes = (assetContainer: AssetContainer) => {
  const allMeshes: { [key: string]: AbstractMesh } = _.keyBy(
    assetContainer.meshes,
    'id',
  )

  // Populate with parents and parents of parents.
  _.forEach(assetContainer.meshes, mesh => {
    _.forEach(getParentMeshes(mesh), parentMesh => {
      allMeshes[parentMesh.id] = parentMesh
    })
  })

  return allMeshes
}

const applyImageTextureProp = (
  renderState: RenderState,
  nextState: SceneObjectStateStatusLoaded,
  data: SceneObjectData,
  payload: ScreenTexturePayload,
) => {
  const { assetContainer, defaultTextures } = nextState
  const { uuid } = data

  const material = _.find(assetContainer.materials, {
    id: payload.material,
  })

  if (!material) {
    console.error(
      `Material ${payload.material} not found on sceneObject ${uuid}!`,
    )
    return
  }

  const materialClassName = material.getClassName()
  if (materialClassName !== 'PBRMaterial') {
    console.error('Unknown material', materialClassName, payload.material)
    return
  }
  const pbrMaterial = material as PBRMaterial

  let baseTexture: Nullable<BaseTexture> = null
  switch (payload.node) {
    case ImageTextureNode.Base:
      baseTexture = pbrMaterial.albedoTexture
      break
    case ImageTextureNode.Emissive:
      baseTexture = pbrMaterial.emissiveTexture
      break
    case ImageTextureNode.Normal:
      baseTexture = pbrMaterial.bumpTexture
      break
    case ImageTextureNode.Metallic:
      baseTexture = pbrMaterial.metallicTexture
      break
    case ImageTextureNode.Roughness:
      baseTexture = pbrMaterial.metallicTexture
      break
    case ImageTextureNode.Clearcoat:
      baseTexture = pbrMaterial.clearCoat.texture
      break

    default:
      console.error('Unrecognized payload.node', payload.node)
      return
  }

  if (!baseTexture) {
    console.error('Missing baseTexture', payload.node, pbrMaterial)
    return
  }

  if (!(baseTexture as Texture).url) {
    console.error('Missing baseTexture.url', baseTexture.getClassName())
    return
  }
  const texture = baseTexture as Texture

  if (defaultTextures[payload.node] && texture.url) {
    defaultTextures[payload.node] = texture.url
  }

  const uri = payload.imageUri || defaultTextures[payload.node]
  if (!uri) {
    console.error('Missing texture uri')
    return
  }

  if (uri !== texture.url) {
    texture.updateURL(uri, null, () => {
      setDirty(renderState.scene!)
    })
  }
}

const applyEmissionProp = (
  renderState: RenderState,
  nextState: SceneObjectStateStatusLoaded,
  data: SceneObjectData,
  payload: EmissionPayload,
) => {
  const { assetContainer } = nextState
  const { uuid } = data

  const material = _.find(assetContainer.materials, {
    id: payload.material,
  }) as StandardMaterial | undefined

  if (!material) {
    console.error(
      `Material ${payload.material} not found on sceneObject ${uuid}!`,
    )
    return
  }

  if (material.emissiveColor.r !== payload.value) {
    material.emissiveColor.set(payload.value, payload.value, payload.value)
  }
}

const applyMaterialProp = (
  renderState: RenderState,
  nextState: SceneObjectStateStatusLoaded,
  data: SceneObjectData,
  payload: MaterialPayload,
) => {
  const { assetContainer, allMeshes } = nextState
  const { uuid } = data

  let isDirty = false

  const matLibMesh = allMeshes['matLib']

  if (!matLibMesh) {
    console.error(`Mesh matLib not found on sceneObject ${uuid}!`)
    return
  }

  if (matLibMesh.isEnabled()) {
    matLibMesh.setEnabled(false)

    isDirty = true
  }

  _.forEach(payload.values, payloadValue => {
    _.forEach(payloadValue.meshes, meshId => {
      const mesh = allMeshes[meshId]

      if (!mesh) {
        console.error(`Mesh ${meshId} not found on sceneObject ${uuid}!`)
        return
      }

      let workingMesh: AbstractMesh
      if (mesh.getClassName() === 'InstancedMesh') {
        workingMesh = _.last(getParentMeshes(mesh)) as Mesh
      } else {
        workingMesh = mesh
      }

      const material = _.find(assetContainer.materials, {
        id: payloadValue.material,
      }) as StandardMaterial | undefined

      if (!material) {
        console.error(
          `Material ${payloadValue.material} not found on sceneObject ${uuid}!`,
        )
        return
      }

      if (workingMesh.material !== material) {
        workingMesh.material = material

        isDirty = true
      }
    })
  })

  if (isDirty) {
    setDirty(renderState.scene!)
  }
}

const applyPresetProp = (
  renderState: RenderState,
  nextState: SceneObjectStateStatusLoaded,
  data: SceneObjectData,
  payload: PresetPropertyData['payload'],
) => {
  const { allMeshes } = nextState
  const { uuid } = data

  let isDirty = false

  _.forEach(payload.visibilities, (isVisible, meshId) => {
    const mesh = allMeshes[meshId]

    if (!mesh) {
      console.error(`Mesh ${meshId} not found on sceneObject ${uuid}!`)
      return
    }

    if (mesh.isEnabled() !== isVisible) {
      mesh.setEnabled(isVisible)

      isDirty = true
    }
  })

  if (isDirty) {
    setDirty(renderState.scene!)
  }
}

const updateSceneObject = (
  renderState: RenderState,
  state: SceneObjectState | undefined,
  data: SceneObjectData | undefined,
): SceneObjectState | undefined => {
  const localState = getLocalState(renderState.scene!)
  let nextState = state

  if (!nextState) {
    if (!data) {
      // EMPTY
      return nextState
    }

    if (data.isHidden) {
      return nextState
    }

    // ADD
    nextState = loadSceneObject(
      data,
      renderState.scene!,
      renderState.onLoadUpdate,
      localState,
    )
  }

  if (!nextState) {
    return undefined
  }

  if (!data) {
    // REMOVE
    removeSceneObject(renderState, nextState, localState)
    return undefined
  }

  const { uuid, rootUrl, sceneFilename } = data
  const prevData = localState.data.sceneObjects[uuid]
  if (
    prevData &&
    (rootUrl !== prevData.rootUrl || sceneFilename !== prevData.sceneFilename)
  ) {
    // CHANGE
    removeSceneObject(renderState, nextState, localState)
    return loadSceneObject(
      data,
      renderState.scene!,
      renderState.onLoadUpdate,
      localState,
    )
  }

  const loadState = localState.assetLoaders[data.uuid]
  if (nextState.status === 'loading' && loadState && loadState.assetContainer) {
    // FINISH LOADING
    delete localState.assetLoaders[data.uuid]
    const { rootContainer } = nextState
    const { assetContainer } = loadState

    // Remove placeholder
    _.each(rootContainer.getChildMeshes(), mesh => mesh.dispose(false, true))

    rootContainer.name = sceneFilename

    _.each(assetContainer.meshes, mesh => {
      if (!mesh.parent) {
        // Content gets loaded with Y rotation inverted. Resetting.
        mesh.rotationQuaternion = null
        mesh.parent = rootContainer
      }
    })
    assetContainer.addAllToScene()

    setDirty(renderState.scene!)

    const allMeshes = getAllMeshes(assetContainer)

    nextState = {
      uuid,
      sceneFilename,
      rootContainer,
      isSelected: false,
      status: 'loaded',
      assetContainer,
      allMeshes,
      defaultTextures: {},
    }
  }

  const { rootContainer } = nextState

  fromDumbVector3(rootContainer.position, data.position)

  const rotation = new Quaternion()
  Quaternion.FromEulerAnglesToRef(
    data.rotation.x,
    data.rotation.y,
    data.rotation.z,
    rotation,
  )
  rootContainer.rotationQuaternion = rotation

  rootContainer.scaling = new Vector3(data.scale.x, data.scale.y, data.scale.z)

  const parent = rootContainer.parent as Mesh
  if (parent === renderState.gizmoContainer) {
    // If the parent is gizmoContainer, subtract position and rotation.
    rootContainer.position = rootContainer.position.subtract(parent.position)
    rootContainer.rotationQuaternion =
      rootContainer.rotationQuaternion.multiply(
        Quaternion.Inverse(parent.rotationQuaternion!),
      )
  }

  if (nextState.status === 'loaded') {
    // UPDATE SETTINGS
    const nextStateLoaded = nextState as SceneObjectStateStatusLoaded

    const isSelectedChanged = !!nextState.isSelected !== data.isSelected
    if (isSelectedChanged) {
      onIsSelectedChange(renderState, [nextStateLoaded], data.isSelected)
    }

    rootContainer.isPickable = data.isDraggable

    if (data.isHidden === rootContainer.isEnabled(false)) {
      rootContainer.setEnabled(!data.isHidden)
    }

    if (nextState.assetContainer) {
      const { properties } = data

      _.each(properties, (propData, propId) => {
        if (propData?.type === SceneObjectPropertyType.ImageTexture) {
          return applyImageTextureProp(
            renderState,
            nextStateLoaded,
            data,
            propData.payload,
          )
        }

        if (propData?.type === SceneObjectPropertyType.Preset) {
          return applyPresetProp(
            renderState,
            nextStateLoaded,
            data,
            propData.payload,
          )
        }

        if (propData?.type === SceneObjectPropertyType.Emission) {
          return applyEmissionProp(
            renderState,
            nextStateLoaded,
            data,
            propData.payload,
          )
        }

        if (propData?.type === SceneObjectPropertyType.Material) {
          return applyMaterialProp(
            renderState,
            nextStateLoaded,
            data,
            propData.payload,
          )
        }

        if (propData?.type === SceneObjectPropertyType.Hdri) {
          // Handled in RenderData
          return
        }

        // Nothing to do here.
        if (
          propId === SceneObjectPropertyType.IsotropicScaling ||
          propId === SceneObjectPropertyType.AnisotropicScaling
        ) {
          return
        }

        // TODO Implement this in Live viewer
        // Catching explicitly to avoid errors for a known issue
        if (propId === 'lidJoint') {
          return
        }

        console.error('Unhandled property', propId, propData)
      })
    }
  }

  return nextState
}

// ---
// IN
// ---
export const updateRenderState = (
  state: RenderState,
  renderData: RenderData,
) => {
  let renderState = state

  renderState = updateScene(renderState, renderState.scene, renderData)

  const localState = getLocalState(renderState.scene!)

  if (localState.hasInputChanges || localState.isInputActive) {
    // Ignoring update because input is active.
    return renderState
  }

  renderState = updateRenderCamera(renderState, renderData)
  renderState = updateLayoutCamera(renderState, renderData)
  renderState = updateActiveCamera(renderState, renderData)

  renderState = updateCanvasSize(renderState, renderData)

  renderState = updateLayerImage(
    renderState,
    renderState.background,
    renderData,
    'background',
  )
  renderState = updateLayerImage(
    renderState,
    renderState.foreground,
    renderData,
    'foreground',
  )

  renderState = updateHdri(
    renderState,
    renderState.hdriTexture,
    renderData.hdri,
  )
  renderState = updateGround(
    renderState,
    renderState.ground,
    renderData.isGridVisible,
  )

  renderState = updateSceneObjects(
    renderState,
    renderState.sceneObjects,
    renderData.sceneObjects,
  )

  renderState = updateGizmo(renderState, renderData)

  localState.data = renderData
  localState.state = renderState

  setDirty(renderState.scene!)

  return renderState
}

// ---
// OUT
// ---

export const renderStateToDocDiff = (
  renderState: RenderState,
  blocs: DocBlocs,
): DocDiff => {
  if (!renderState.renderCamera) {
    return {}
  }

  return {
    camera: {
      distance: {
        value: unitFromM(
          renderState.renderCamera.radius,
          blocs.camera.distance.value,
        ),
        unit: blocs.camera.distance.value.unit,
      },
      position: {
        x: {
          value: unitFromM(
            renderState.renderCamera.target.x,
            blocs.camera.position.x.value,
          ),
          unit: blocs.camera.position.x.value.unit,
        },
        y: {
          value: unitFromM(
            renderState.renderCamera.target.y,
            blocs.camera.position.y.value,
          ),
          unit: blocs.camera.position.y.value.unit,
        },
        z: {
          value: unitFromM(
            renderState.renderCamera.target.z,
            blocs.camera.position.z.value,
          ),
          unit: blocs.camera.position.z.value.unit,
        },
      },
      rotation: {
        x: {
          value: unitFromRadian(
            renderState.renderCamera.beta,
            blocs.camera.rotation.x.value,
          ),
          unit: blocs.camera.rotation.x.value.unit,
        },
        y: {
          value: unitFromRadian(
            renderState.renderCamera.alpha + Math.PI / 2,
            blocs.camera.rotation.y.value,
          ),
          unit: blocs.camera.rotation.y.value.unit,
        },
      },
    },
    sceneObjects: _.mapValues(
      renderState.sceneObjects,
      (sceneObjectState, uuid) => {
        if (sceneObjectState.status !== 'loaded') {
          return
        }

        const sceneObjectBlocs = blocs.sceneObjects[uuid]

        const { rootContainer } = sceneObjectState

        const parent = rootContainer.parent as Mesh

        let position = rootContainer.position
        let rotationQuaternion = rootContainer.rotationQuaternion!
        if (parent === renderState.gizmoContainer) {
          // If the parent is gizmoContainer, add position and rotation.
          position = position.add(parent.position)
          rotationQuaternion = rotationQuaternion.multiply(
            parent.rotationQuaternion!,
          )
        }

        const rotation = TEMP_VECTOR3
        rotationQuaternion?.toEulerAnglesToRef(rotation)

        return {
          isSelected: sceneObjectState.isSelected,
          position: {
            x: {
              value: unitFromM(position.x, sceneObjectBlocs.position.x.value),
              unit: sceneObjectBlocs.position.x.value.unit,
            },
            y: {
              value: unitFromM(position.y, sceneObjectBlocs.position.y.value),
              unit: sceneObjectBlocs.position.y.value.unit,
            },
            z: {
              value: unitFromM(position.z, sceneObjectBlocs.position.z.value),
              unit: sceneObjectBlocs.position.z.value.unit,
            },
          },
          rotation: {
            x: {
              value: unitFromRadian(
                rotation.x,
                sceneObjectBlocs.rotation.x.value,
              ),
              unit: sceneObjectBlocs.rotation.x.value.unit,
            },
            y: {
              value: unitFromRadian(
                rotation.y,
                sceneObjectBlocs.rotation.y.value,
              ),
              unit: sceneObjectBlocs.rotation.y.value.unit,
            },
            z: {
              value: unitFromRadian(
                rotation.z,
                sceneObjectBlocs.rotation.z.value,
              ),
              unit: sceneObjectBlocs.rotation.z.value.unit,
            },
          },
          scale: {
            x: rootContainer.scaling.x,
            y: rootContainer.scaling.y,
            z: rootContainer.scaling.z,
          },
        }
      },
    ),
  }
}
