import { FontWeights, IStyle, Stack, Text } from 'office-ui-fabric-react'
import React from 'react'

import { ActivePersona } from '../components/ActivePersona'
import { AccountInfo } from '../store/auth/state'

export interface IProps {
  isFullscreenMode: boolean
  push: (path: string) => void
  breadcrumbs?: JSX.Element
  account: AccountInfo
}

const styles = {
  header: {
    root: {
      height: 40,
      backgroundColor: '#ED1C23',
    },
  },
  logo: {
    root: {
      color: '#FFFFFF',
      fontSize: 16,
      fontWeight: FontWeights.bold,
      position: 'relative',
      lineHeight: 40,
      top: -1,
      cursor: 'pointer',
    } as IStyle,
  },
}

const tokens = {
  header: {
    padding: '0px 20px',
  },
}

// Wrapping in `div` prevents the header from shrinking.
// The `disableShrink` prop does not work in this case.
export const Header = (props: IProps) => {
  const { account, isFullscreenMode } = props
  if (isFullscreenMode) {
    return null
  }

  return (
    <div>
      <Stack
        styles={styles.header}
        verticalAlign="center"
        tokens={tokens.header}
        horizontal
      >
        <Stack
          onClick={() => {
            props.push('/')
          }}
        >
          <Text styles={styles.logo}>Render Tool</Text>
        </Stack>
        <Stack grow={1}>{props.breadcrumbs}</Stack>
        <Stack>
          <ActivePersona account={account} />
        </Stack>
      </Stack>
    </div>
  )
}
