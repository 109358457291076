import React from 'react'
import { connect } from 'react-redux'

import { type Dispatch, bindActionCreators } from 'redux'
import { ActionsMenu } from '../../../components/ActionsMenu'
import { DropdownInput } from '../../../containers/DropdownInput'
import { Panel } from '../../../containers/Panel'
import { SliderInput } from '../../../containers/SliderInput'
import { TextGroupInput } from '../../../containers/TextGroupInput'
import type { State } from '../../../store'
import { mergeDoc, setInDoc } from '../../../store/doc/actions'
import { getDocBlocs } from '../../../store/doc/selectors'
import { getInitialCameraState } from '../../../store/doc/state'
import { CheckboxInput } from '../../../containers/CheckboxInput'
import { LayoutMode } from '../../../components/CheckboxInput'

type StateProps = {
  docBlocs: ReturnType<typeof getDocBlocs>
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  docBlocs: getDocBlocs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInDoc,
      mergeDoc,
    },
    dispatch,
  )

const CameraActions = (props: Props) => {
  const isLockedBloc = props.docBlocs.camera.isLocked
  return (
    <ActionsMenu
      quickActions={[
        {
          key: 'lock',
          title: isLockedBloc.value ? 'Unlock' : 'Lock',
          iconProps: {
            iconName: isLockedBloc.value ? 'Lock' : 'Unlock',
          },
          className: isLockedBloc.value ? 'active' : undefined,
          onClick: event => {
            event?.stopPropagation()
            props.setInDoc({
              path: isLockedBloc.path,
              value: !isLockedBloc.value,
            })
          },
        },
        {
          key: 'reset',
          title: 'Reset',
          iconProps: {
            // TODO Revert when https://github.com/microsoft/fluentui/issues/12529 is fixed
            // iconName: 'Reset',
            iconName: 'Undo',
          },
          onClick: event => {
            event?.stopPropagation()
            props.mergeDoc({
              camera: getInitialCameraState(),
            })
          },
        },
      ]}
    />
  )
}

export const CameraPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => (
  <Panel id="camera" label="Camera" actions={<CameraActions {...props} />}>
    <DropdownInput
      bloc={props.docBlocs.camera.focalLength}
      label="Focal Length"
    />
    <CheckboxInput
      bloc={props.docBlocs.camera.dof.isEnabled}
      label="Depth of Field"
      makeLabelBold={true}
      layoutMode={LayoutMode.fill}
    />
    <DropdownInput bloc={props.docBlocs.camera.dof.fstop} label="f-stop" />
    <SliderInput bloc={props.docBlocs.camera.distance} label="Distance" />
    <TextGroupInput
      blocs={[
        props.docBlocs.camera.position.x,
        props.docBlocs.camera.position.y,
        props.docBlocs.camera.position.z,
      ]}
      label="Position"
      unit='"'
      labels={['X', 'Y', 'Z']}
    />
    <SliderInput bloc={props.docBlocs.camera.rotation.x} label="Rotation X" />
    <SliderInput bloc={props.docBlocs.camera.rotation.y} label="Rotation Y" />
  </Panel>
))
