import _ from 'lodash'

import type * as dto from '../../dto/api'
import type { Doc } from '../doc/state'
import { getRenderJobThumbFromAPI } from '../jobs/dataTransformers'
import type { PartialDocRevision } from './types'
import { defaultFStop } from '../canvasPresets/staticData'

export const revisionFromApi = (
  revision: dto.DocumentRevisionWithStatus,
  documentId: number,
): PartialDocRevision => {
  const docData = revision.data as Doc
  if (!docData.camera.dof) {
    docData.camera.dof = {
      isEnabled: false,
      fstop: defaultFStop,
    }
  }
  return {
    documentId,
    version: revision.version,
    doc: {
      ...docData,
      meta: {
        ...docData.meta,
        id: documentId,
      },
    },
    createdAt: revision.createdAt,
    createdBy: revision.createdBy,
    status: revision.renderStatus,
    proxyThumbnail: getRenderJobThumbFromAPI(revision.renderStatus.preview),
    finalThumbnail: getRenderJobThumbFromAPI(revision.renderStatus.final),
  }
}

export const revisionsFromApi = (
  revisions: dto.DocumentRevisionWithStatus[],
  documentId: number,
): PartialDocRevision[] =>
  _.map(revisions, revision => revisionFromApi(revision, documentId))
