import { connect } from 'react-redux'

import { CheckboxInput as Component } from '../components/CheckboxInput'
import { Dispatch, State } from '../store'
import { setInDoc } from '../store/doc/actions'
import { Bloc } from '../store/doc/types'
import { pathToId } from '../utils'

type OwnProps = {
  bloc: Bloc<any>
  label: string
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { bloc } = ownProps
  return {
    id: pathToId(bloc.path),
    isChecked: bloc.value,
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { bloc } = ownProps
  return {
    setValue: (value: boolean) =>
      dispatch(setInDoc({ path: bloc.path, value })),
  }
}

const CheckboxInput = connect(mapStateToProps, mapDispatchToProps)(Component)

export { CheckboxInput }
