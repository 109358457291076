import { createSelector } from 'reselect'

import { focalLengths, fStops } from '../../canvasPresets/staticData'
import { docSelector } from './doc'

export const cameraIsLocked = createSelector([docSelector], doc => {
  const path = ['camera', 'isLocked']
  const value = doc.camera.isLocked
  return {
    path,
    value,
  }
})

export const cameraFocalLength = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'focalLength']
    const value = doc.camera.focalLength
    return {
      path,
      value,
      options: focalLengths,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraDistance = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'distance']
    const value = doc.camera.distance
    return {
      path,
      value,
      min: 1,
      max: 1000,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraPositionX = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'position', 'x']
    const value = doc.camera.position.x
    return {
      path,
      value,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraPositionY = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'position', 'y']
    const value = doc.camera.position.y
    return {
      path,
      value,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraPositionZ = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'position', 'z']
    const value = doc.camera.position.z
    return {
      path,
      value,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraRotationX = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'rotation', 'x']
    const value = doc.camera.rotation.x
    return {
      path,
      value,
      // TODO should match ArcRotateCamera range, as before
      min: -180,
      max: 180,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraRotationY = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'rotation', 'y']
    const value = doc.camera.rotation.y
    return {
      path,
      value,
      min: -180,
      max: 180,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraRotationZ = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'rotation', 'z']
    const value = doc.camera.rotation.z
    return {
      path,
      value,
      min: -180,
      max: 180,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraDOFIsEnabled = createSelector(
  [docSelector, cameraIsLocked],
  (doc, isLocked) => {
    const path = ['camera', 'dof', 'isEnabled']
    const value = doc.camera.dof.isEnabled
    return {
      path,
      value,
      isDisabled: isLocked.value,
    }
  },
)

export const cameraDOFFStop = createSelector(
  [docSelector, cameraIsLocked, cameraDOFIsEnabled],
  (doc, isLocked, isDOFEnabled) => {
    const path = ['camera', 'dof', 'fstop']
    const value = doc.camera.dof.fstop
    return {
      path,
      value,
      options: fStops,
      isDisabled: isLocked.value || !isDOFEnabled.value,
    }
  },
)
