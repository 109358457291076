import { FontWeights, PrimaryButton, Stack, Text } from 'office-ui-fabric-react'
import React from 'react'

const styles = {
  wrapper: {
    root: {
      maxWidth: 400,
      width: '100%',
    },
  },
  title: {
    root: {
      color: '#0078D4',
      fontSize: 28,
      fontWeight: FontWeights.bold,
      textAlign: 'center',
    },
  },
  text: {
    root: {
      color: '#323130',
      fontSize: 14,
      textAlign: 'center',
    },
  },
  buttons: {
    root: {
      maxWidth: 300,
      width: '100%',
      paddingTop: 10,
    },
  },
}

const openLiveIDLogin = () => {
  window.location.href = '/account/live/login'
}

const openGoogleLogin = () => {
  window.location.href = '/account/google/login'
}

export const Login = () => {
  return (
    <Stack verticalFill verticalAlign="center" horizontalAlign="center">
      <Stack
        styles={styles.wrapper}
        tokens={{ childrenGap: 10 }}
        horizontalAlign="center"
      >
        <Text styles={styles.title}>Render Tool</Text>
        <Text styles={styles.text}>
          Sign in with your account to access Render Tool.
        </Text>
        <Text styles={styles.text}>For Authorized users only.</Text>
        <Stack styles={styles.buttons} tokens={{ childrenGap: 20 }}>
          <PrimaryButton onClick={openLiveIDLogin}>
            Login With Live ID
          </PrimaryButton>
          <PrimaryButton onClick={openGoogleLogin}>
            Login With Google
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
