import _ from 'lodash'
import { createSelector, type Selector } from 'reselect'

import type { State } from '..'
import { sidebarVisibleAssetTypes } from '../assets/state'
import mapValuesToState, { type SelectorTree } from '../utils/mapValuesToState'
import {
  cameraDistance,
  cameraDOFIsEnabled,
  cameraDOFFStop,
  cameraFocalLength,
  cameraIsLocked,
  cameraPositionX,
  cameraPositionY,
  cameraPositionZ,
  cameraRotationX,
  cameraRotationY,
  cameraRotationZ,
} from './blocs/camera'
import {
  canvasMedium,
  canvasPreset,
  canvasPrintBleed,
  canvasPrintResolution,
  canvasSizeHeight,
  canvasSizeWidth,
} from './blocs/canvas'
import {
  metaId,
  metaIsApproved,
  metaIsTemplate,
  metaName,
  metaVersion,
} from './blocs/meta'
import {
  sceneObjectIsHidden,
  sceneObjectIsLocked,
  sceneObjectIsSelected,
  sceneObjectLatestRevisionSelector,
  sceneObjectModelSelector,
  sceneObjectPositionX,
  sceneObjectPositionY,
  sceneObjectPositionZ,
  sceneObjectProperties,
  sceneObjectRevisionSelector,
  sceneObjectRotationX,
  sceneObjectRotationY,
  sceneObjectRotationZ,
  sceneObjectScaleX,
  sceneObjectScaleY,
  sceneObjectScaleZ,
} from './blocs/sceneObjects'
import {
  visualsBackground,
  visualsEnvironmentScene,
  visualsForeground,
} from './blocs/visuals'
import type { BlocGuard, BlocMapGuard } from './types'
import type { AssetType } from '../assets/types'

// Store scene object selectors to avoid creating new ones every time.
const _sceneObjectSelectors: {
  [sceneObjectId: string]: SelectorTree<State, any>
} = {}

export const docSelector = (state: State) => state.doc

export const isDocCreatedSelector = createSelector(
  [docSelector],
  doc => doc.meta.id !== 0,
)

export const docTree: SelectorTree<State, DocBlocs> = {
  meta: {
    id: metaId,
    version: metaVersion,
    name: metaName,
    isTemplate: metaIsTemplate,
    isApproved: metaIsApproved,
  },

  canvasPreset,

  canvas: {
    medium: canvasMedium,
    size: {
      width: canvasSizeWidth,
      height: canvasSizeHeight,
    },
    print: {
      resolution: canvasPrintResolution,
      bleed: canvasPrintBleed,
    },
  },

  camera: {
    isLocked: cameraIsLocked,
    focalLength: cameraFocalLength,
    dof: {
      isEnabled: cameraDOFIsEnabled,
      fstop: cameraDOFFStop,
    },
    distance: cameraDistance,
    position: {
      x: cameraPositionX,
      y: cameraPositionY,
      z: cameraPositionZ,
    },
    rotation: {
      x: cameraRotationX,
      y: cameraRotationY,
      z: cameraRotationZ,
    },
  },

  visuals: {
    background: visualsBackground as unknown as Selector<
      State,
      SceneObjectBlocs
    >,
    foreground: visualsForeground as unknown as Selector<
      State,
      SceneObjectBlocs
    >,
    environmentScene: visualsEnvironmentScene as unknown as Selector<
      State,
      SceneObjectBlocs
    >,
  },
  sceneObjects: (state: State) =>
    _.mapValues(
      state.doc.sceneObjects,
      (sceneObject: { id: string; type: string }) => {
        let selectors = _sceneObjectSelectors[sceneObject.id]

        if (!selectors) {
          selectors = {
            id: () => sceneObject.id,
            type: () => sceneObject.type,
            sceneObject: sceneObjectModelSelector(sceneObject.id),
            revision: sceneObjectRevisionSelector(sceneObject.id),
            latestRevision: sceneObjectLatestRevisionSelector(sceneObject.id),
            isLocked: sceneObjectIsLocked(sceneObject.id),
            isHidden: sceneObjectIsHidden(sceneObject.id),
            isSelected: sceneObjectIsSelected(sceneObject.id),
            position: {
              x: sceneObjectPositionX(sceneObject.id),
              y: sceneObjectPositionY(sceneObject.id),
              z: sceneObjectPositionZ(sceneObject.id),
            },
            rotation: {
              x: sceneObjectRotationX(sceneObject.id),
              y: sceneObjectRotationY(sceneObject.id),
              z: sceneObjectRotationZ(sceneObject.id),
            },
            scale: {
              x: sceneObjectScaleX(sceneObject.id),
              y: sceneObjectScaleY(sceneObject.id),
              z: sceneObjectScaleZ(sceneObject.id),
            },
            properties: sceneObjectProperties(sceneObject.id),
          }

          _sceneObjectSelectors[sceneObject.id] = selectors
        }

        return mapValuesToState(selectors)(state)
        // TODO Make the logic return the expected type
      },
    ),
}

export type SceneObjectBlocs = {
  id: string
  type: AssetType
  sceneObject: ReturnType<ReturnType<typeof sceneObjectModelSelector>>
  revision: ReturnType<ReturnType<typeof sceneObjectRevisionSelector>>
  latestRevision: ReturnType<
    ReturnType<typeof sceneObjectLatestRevisionSelector>
  >
  isLocked: BlocGuard<ReturnType<ReturnType<typeof sceneObjectIsLocked>>>
  isHidden: BlocGuard<ReturnType<ReturnType<typeof sceneObjectIsHidden>>>
  isSelected: BlocGuard<ReturnType<ReturnType<typeof sceneObjectIsSelected>>>
  position: {
    x: BlocGuard<ReturnType<ReturnType<typeof sceneObjectPositionX>>>
    y: BlocGuard<ReturnType<ReturnType<typeof sceneObjectPositionY>>>
    z: BlocGuard<ReturnType<ReturnType<typeof sceneObjectPositionZ>>>
  }
  rotation: {
    x: BlocGuard<ReturnType<ReturnType<typeof sceneObjectRotationX>>>
    y: BlocGuard<ReturnType<ReturnType<typeof sceneObjectRotationY>>>
    z: BlocGuard<ReturnType<ReturnType<typeof sceneObjectRotationZ>>>
  }
  scale: {
    x: BlocGuard<ReturnType<ReturnType<typeof sceneObjectScaleX>>>
    y: BlocGuard<ReturnType<ReturnType<typeof sceneObjectScaleY>>>
    z: BlocGuard<ReturnType<ReturnType<typeof sceneObjectScaleZ>>>
  }
  properties: BlocMapGuard<ReturnType<ReturnType<typeof sceneObjectProperties>>>
}

export type DocBlocs = {
  meta: {
    id: BlocGuard<ReturnType<typeof metaId>>
    version: BlocGuard<ReturnType<typeof metaVersion>>
    name: BlocGuard<ReturnType<typeof metaName>>
    isTemplate: BlocGuard<ReturnType<typeof metaIsTemplate>>
    isApproved: BlocGuard<ReturnType<typeof metaIsApproved>>
  }

  canvasPreset: BlocGuard<ReturnType<typeof canvasPreset>>

  canvas: {
    medium: BlocGuard<ReturnType<typeof canvasMedium>>
    size: {
      width: BlocGuard<ReturnType<typeof canvasSizeWidth>>
      height: BlocGuard<ReturnType<typeof canvasSizeHeight>>
    }
    print: {
      resolution: BlocGuard<ReturnType<typeof canvasPrintResolution>>
      bleed: BlocGuard<ReturnType<typeof canvasPrintBleed>>
    }
  }

  camera: {
    isLocked: BlocGuard<ReturnType<typeof cameraIsLocked>>
    focalLength: BlocGuard<ReturnType<typeof cameraFocalLength>>
    dof: {
      isEnabled: BlocGuard<ReturnType<typeof cameraDOFIsEnabled>>
      fstop: BlocGuard<ReturnType<typeof cameraDOFFStop>>
    }
    distance: BlocGuard<ReturnType<typeof cameraDistance>>
    position: {
      x: BlocGuard<ReturnType<typeof cameraPositionX>>
      y: BlocGuard<ReturnType<typeof cameraPositionY>>
      z: BlocGuard<ReturnType<typeof cameraPositionZ>>
    }
    rotation: {
      x: BlocGuard<ReturnType<typeof cameraRotationX>>
      y: BlocGuard<ReturnType<typeof cameraRotationY>>
      z: BlocGuard<ReturnType<typeof cameraRotationZ>>
    }
  }

  visuals: {
    background: BlocGuard<ReturnType<typeof visualsBackground>>
    foreground: BlocGuard<ReturnType<typeof visualsForeground>>
    environmentScene: BlocGuard<ReturnType<typeof visualsEnvironmentScene>>
  }

  sceneObjects: {
    [key: string]: SceneObjectBlocs
  }
}

export const getDocBlocs = (state: State): DocBlocs =>
  mapValuesToState<State, DocBlocs>(docTree)(state)

export const sceneObjectsSelector = createSelector(
  [getDocBlocs],
  docBlocs => docBlocs.sceneObjects,
)

export const sidebarVisibleSceneObjectsSelector = createSelector(
  [sceneObjectsSelector],
  sceneObjects => {
    return _.filter(sceneObjects, sceneObject =>
      _.includes(sidebarVisibleAssetTypes, sceneObject.type),
    )
  },
)

const w = window as any
w.getDocBlocs = getDocBlocs
