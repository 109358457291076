import {
  Checkbox as FabricCheckbox,
  FontWeights,
  IStyle,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'
import * as commonStyles from '../styles'

export enum LayoutMode {
  fixed = 0,
  fill = 1,
}

export interface Props {
  id: string
  label: string
  isChecked: boolean | undefined
  isIndeterminate?: boolean | undefined
  makeLabelBold?: boolean
  disabled?: boolean
  setValue: (isChecked: boolean) => void
  layoutMode?: LayoutMode
}

type Event = React.FormEvent<HTMLInputElement | HTMLElement>

// Hack.
const getStyles = memoizeFunction((boldLabel: boolean) => {
  return {
    fixed: {
      text: Object.assign({}, commonStyles.label.base, {
        order: -1,
        maxWidth: 280,
        marginLeft: 0,
        marginRight: 10,

        fontWeight: boldLabel ? FontWeights.semibold : FontWeights.regular,
        top: 0,
      }) as IStyle,
      checkbox: {
        marginRight: 0,
      },
    },
    fill: {
      label: {
        justifyContent: 'space-between',
        width: '100%',
      },
      text: Object.assign({}, commonStyles.label.base, {
        order: -1,
        width: '100%',
        maxWidth: 1000,
        marginLeft: 0,
        marginRight: 20,

        fontWeight: boldLabel ? FontWeights.semibold : FontWeights.regular,
        top: 0,
      }) as IStyle,
      checkbox: {
        marginRight: 0,
      },
    },
  }
})

export const CheckboxInput = (props: Props) => {
  const onChange = (_?: Event, isChecked?: boolean) => {
    let value = isChecked
    if (value === undefined) {
      value = false
    }

    props.setValue(value)
  }

  const { layoutMode = LayoutMode.fixed } = props

  const styles = getStyles(!!props.makeLabelBold)

  return (
    <FabricCheckbox
      id={props.id}
      checked={props.isChecked}
      indeterminate={props.isIndeterminate}
      onChange={onChange}
      disabled={props.disabled}
      label={props.label}
      title={props.label}
      styles={layoutMode === LayoutMode.fixed ? styles.fixed : styles.fill}
    />
  )
}
