// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__line {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__input {
  border: unset;
  background-color: inherit;
  flex-grow: 1;
  width: 100px;
}

.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__valid {
  background-color: white;
}

.\\---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__invalid {
  background-color: rgba(255, 204, 204, 0.8);
}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/TextInputWithSubmit.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,0CAAA;AACJ","sourcesContent":[".line {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.input {\r\n    border: unset;\r\n    background-color: inherit;\r\n    flex-grow: 1;\r\n    width: 100px;\r\n}\r\n\r\n.valid {\r\n    background-color: white;\r\n}\r\n\r\n.invalid {\r\n    background-color: rgba(255, 204, 204, 0.8);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var line = `---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__line`;
export var input = `---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__input`;
export var valid = `---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__valid`;
export var invalid = `---------dev-sharedUiComponents-dist-components-TextInputWithSubmit-module__invalid`;
export default ___CSS_LOADER_EXPORT___;
