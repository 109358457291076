// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___, * as ___CSS_LOADER_ICSS_IMPORT_0____NAMED___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!./Fonts.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------dev-sharedUiComponents-dist-components-Button-module__button {
  width: 55px;
  height: 55px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__wide {
  width: 120px;
  height: 45px;
  justify-content: start;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__small {
  width: 30px;
  height: 30px;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__smaller {
  width: 25px;
  height: 25px;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__light {
  background-color: #E2E2E2;
  color: #333333;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__light:hover, .\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__light.\\---------dev-sharedUiComponents-dist-components-Button-module__active {
  background-color: #FFFFFF;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__light:disabled {
  background-color: #FFFFFF;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__dark {
  background-color: #333333;
  color: #FFFFFF;
}
.\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__dark:hover, .\\---------dev-sharedUiComponents-dist-components-Button-module__button.\\---------dev-sharedUiComponents-dist-components-Button-module__dark.\\---------dev-sharedUiComponents-dist-components-Button-module__active {
  background-color: #666666;
}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/Button.module.scss"],"names":[],"mappings":"AACA;EAEI,WAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAOJ;AANI;EACI,YAAA;EACA,YAAA;EACA,sBAAA;AAQR;AANI;EACI,WAAA;EACA,YAAA;AAQR;AANI;EACI,WAAA;EACA,YAAA;AAQR;AANI;EACI,yBAAA;EACA,cAAA;AAQR;AAPQ;EAEI,yBAAA;AAQZ;AANQ;EACI,yBAAA;AAQZ;AALI;EACI,yBAAA;EACA,cAAA;AAOR;AANQ;EAEI,yBAAA;AAOZ","sourcesContent":["@use \"Colors.scss\" as *;\r\n:local .button {\r\n    composes: fontRegular from \"Fonts.scss\";\r\n    width: 55px;\r\n    height: 55px;\r\n    border: none;\r\n    display: inline-flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 0 0;\r\n    &.wide {\r\n        width: 120px;\r\n        height: 45px;\r\n        justify-content: start;\r\n    }\r\n    &.small {\r\n        width: 30px;\r\n        height: 30px;\r\n    }\r\n    &.smaller {\r\n        width: 25px;\r\n        height: 25px;\r\n    }\r\n    &.light {\r\n        background-color: light-background;\r\n        color: dark-color;\r\n        &:hover,\r\n        &.active {\r\n            background-color: light-active-background;\r\n        }\r\n        &:disabled {\r\n            background-color: light-color;\r\n        }\r\n    }\r\n    &.dark {\r\n        background-color: dark-background;\r\n        color: light-color;\r\n        &:hover,\r\n        &.active {\r\n            background-color: dark-active-background;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
var _1 = `#E2E2E2`;
export { _1 as "light-background" };
var _2 = `#FFFFFF`;
export { _2 as "light-active-background" };
var _3 = `#C8C8C8`;
export { _3 as "light-disabled-background" };
var _4 = `#FFFFFF`;
export { _4 as "light-color" };
var _5 = `#333333`;
export { _5 as "dark-background" };
var _6 = `#666666`;
export { _6 as "dark-active-background" };
var _7 = `#333333`;
export { _7 as "dark-color" };
export var button = `---------dev-sharedUiComponents-dist-components-Button-module__button ${___CSS_LOADER_ICSS_IMPORT_0____NAMED___["fontRegular"]}`;
export var wide = `---------dev-sharedUiComponents-dist-components-Button-module__wide`;
export var small = `---------dev-sharedUiComponents-dist-components-Button-module__small`;
export var smaller = `---------dev-sharedUiComponents-dist-components-Button-module__smaller`;
export var light = `---------dev-sharedUiComponents-dist-components-Button-module__light`;
export var active = `---------dev-sharedUiComponents-dist-components-Button-module__active`;
export var dark = `---------dev-sharedUiComponents-dist-components-Button-module__dark`;
export default ___CSS_LOADER_EXPORT___;
