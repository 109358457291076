// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#metadata-container textarea {
  display: block;
  margin: 4px;
  width: calc(100% - 14px);
  min-height: 100px;
  max-height: 500px;
  resize: vertical;
}

.meta-string textarea {
  background: #ffee99;
}

.meta-json textarea {
  background: #99ddff;
}

.meta-object textarea {
  background: #dd99ff;
}

.meta-object-protect textarea {
  background: #b0b0b0;
}

.buttonLine.disabled {
  opacity: 20%;
  pointer-events: none;
}

.copy-root {
  display: grid;
  justify-content: end;
  height: 30px;
}

.copy-container {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.hoverIcon:hover {
  opacity: 0.8;
}

.type-status {
  padding: 5px 15px;
}`, "",{"version":3,"sources":["webpack://./../../../dev/inspector/dist/components/actionTabs/tabs/propertyGrids/metadata/metadataPropertyGrid.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,WAAA;EACA,wBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AAAR;;AAKI;EACI,mBAAA;AAFR;;AAOI;EACI,mBAAA;AAJR;;AASI;EACI,mBAAA;AANR;;AAWI;EACI,mBAAA;AARR;;AAaI;EACI,YAAA;EACA,oBAAA;AAVR;;AAcA;EACI,aAAA;EACA,oBAAA;EACA,YAAA;AAXJ;;AAcA;EACI,YAAA;EACA,WAAA;EACA,eAAA;AAXJ;;AAcA;EACI,YAAA;AAXJ;;AAcA;EACI,iBAAA;AAXJ","sourcesContent":["#metadata-container {\r\n    textarea {\r\n        display: block;\r\n        margin: 4px;\r\n        width: calc(100% - 14px);\r\n        min-height: 100px;\r\n        max-height: 500px;\r\n        resize: vertical;\r\n    }\r\n}\r\n\r\n.meta-string {\r\n    textarea {\r\n        background: #ffee99;\r\n    }\r\n}\r\n\r\n.meta-json {\r\n    textarea {\r\n        background: #99ddff;\r\n    }\r\n}\r\n\r\n.meta-object {\r\n    textarea {\r\n        background: #dd99ff;\r\n    }\r\n}\r\n\r\n.meta-object-protect {\r\n    textarea {\r\n        background: #b0b0b0;\r\n    }\r\n}\r\n\r\n.buttonLine {\r\n    &.disabled {\r\n        opacity: 20%;\r\n        pointer-events: none;\r\n    }\r\n}\r\n\r\n.copy-root {\r\n    display: grid;\r\n    justify-content: end;\r\n    height: 30px;\r\n}\r\n\r\n.copy-container {\r\n    height: 30px;\r\n    width: 30px;\r\n    cursor: pointer;\r\n}\r\n\r\n.hoverIcon:hover {\r\n    opacity: 0.8;\r\n}\r\n\r\n.type-status {\r\n    padding: 5px 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
