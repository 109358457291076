import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { AssetType } from '../assets/types'
import type { CampaignAssets } from '../campaign/types'
import {
  canvasMediumMap,
  focalLengths,
  defaultFStop,
} from '../canvasPresets/staticData'
import type { CanvasPreset } from '../canvasPresets/types'
import type {
  Canvas,
  CanvasTexture,
  EnvironmentScene,
  FocalLength,
  SceneObject,
  UnitValue,
  Asset,
  FNumber,
} from './types'

type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T

export type Doc = {
  meta: {
    id: number
    version: number
    name: string
    isTemplate: boolean
    isApproved: boolean
  }
  canvasPreset?: CanvasPreset
  canvas: Canvas
  camera: {
    isLocked: boolean
    focalLength: FocalLength
    dof: {
      isEnabled: boolean
      fstop: FNumber
    }
    distance: UnitValue
    position: {
      x: UnitValue
      y: UnitValue
      z: UnitValue
    }
    rotation: {
      x: UnitValue
      y: UnitValue
      z: UnitValue
    }
  }
  visuals: {
    background?: CanvasTexture
    foreground?: CanvasTexture
    environmentScene?: EnvironmentScene
  }
  devices?: { [key: string]: any } // Old way of storing assets, should be removed later.
  sceneObjects: { [key: string]: SceneObject }
}

export type DocDiff = DeepPartial<Doc>

const getInitialCanvasState = (campaignAssets?: CampaignAssets) => {
  const canvasPreset = campaignAssets
    ? _.find(
        campaignAssets.canvasPresets,
        canvasPreset => canvasPreset.name === 'Full HD',
      )
    : undefined

  const canvas = canvasPreset
    ? {
        medium: canvasPreset.medium,
        size: canvasPreset.size,
        print: {
          resolution: canvasPreset.print
            ? canvasPreset.print.resolution
            : undefined,
          bleed: canvasPreset.print ? canvasPreset.print.bleed : undefined,
        },
      }
    : {
        medium: canvasMediumMap.digital,
        size: {
          width: { value: 1920, unit: 'px' },
          height: { value: 1080, unit: 'px' },
        },
        print: {
          resolution: undefined,
          bleed: undefined,
        },
      }
  return {
    canvas,
    canvasPreset,
  }
}

export const getInitialCameraState = () => {
  return {
    isLocked: false,
    focalLength: focalLengths[1],
    dof: {
      isEnabled: false,
      fstop: defaultFStop,
    },
    distance: {
      value: 55,
      unit: '"',
    },
    position: {
      x: {
        value: 0,
        unit: '"',
      },
      y: {
        value: 6,
        unit: '"',
      },
      z: {
        value: 0,
        unit: '"',
      },
    },
    rotation: {
      x: {
        value: 80,
        unit: '°',
      },
      y: {
        value: 0,
        unit: '°',
      },
      z: {
        value: 0,
        unit: '°',
      },
    },
  }
}

export const getInitialSceneObjectState = (scale = { x: 1, y: 1, z: 1 }) => {
  return {
    position: {
      x: {
        unit: '"',
        value: 0,
      },
      y: {
        unit: '"',
        value: 0,
      },
      z: {
        unit: '"',
        value: 0,
      },
    },
    rotation: {
      x: {
        unit: '°',
        value: 0,
      },
      y: {
        unit: '°',
        value: 0,
      },
      z: {
        unit: '°',
        value: 0,
      },
    },
    scale,
    properties: {},
  }
}

const assignFirstAvailableAsset = (
  assets: Asset[] | undefined,
  assetType: AssetType,
  sceneObjects: { [key: string]: SceneObject },
): void => {
  const asset = _.find(assets, asset => asset.type === assetType)

  if (!asset) {
    return
  }
  const id = uuidv4()

  const lastRevision = _.last(asset.revisions)!
  sceneObjects[id] = {
    id,
    type: asset.type as AssetType,
    isLocked: true,
    isHidden: false,
    isSelected: false,
    assetId: asset.id,
    revisionId: lastRevision.id,
    ...getInitialSceneObjectState(),
  }
}

export const initialState = (campaignAssets?: CampaignAssets): Doc => {
  const { canvas, canvasPreset } = getInitialCanvasState(campaignAssets)
  const sceneObjects: { [key: string]: SceneObject } = {}

  assignFirstAvailableAsset(
    campaignAssets?.assets,
    AssetType.BaseScene,
    sceneObjects,
  )
  assignFirstAvailableAsset(
    campaignAssets?.assets,
    AssetType.Environment,
    sceneObjects,
  )
  assignFirstAvailableAsset(
    campaignAssets?.assets,
    AssetType.Light,
    sceneObjects,
  )

  return {
    meta: {
      id: 0,
      version: 0,
      name: '',
      isTemplate: false,
      isApproved: false,
    },
    canvasPreset,
    canvas,
    camera: getInitialCameraState(),
    visuals: {
      background: undefined,
      foreground: undefined,
      environmentScene: undefined,
    },
    sceneObjects,
  }
}
